import React from "react";
import vivanSolo from "../images/vivianSolo.jpg";
import tinaSolo from "../images/tina-solo-new.jpg";
import vioolTurned from "../images/vioolTurned.png";
import vioolDoodle from "../images/viool.png";
import Layout from "../components/Layout";
import smile from "../images/smile.jpg";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function overOns() {
  return (
    <Layout>
     <div className="over-ons-page">
        <section className="general-container">
          <h1>Over ons</h1>
          <p className="long-text">
            Wij zijn Duo Juna. We kennen elkaar via het JeugdOrkest Nederland
            (JON). Tijdens de tournees naar het buitenland en de
            repetitieweekenden hadden we gelijk een klik. We gingen veel met
            elkaar om en vonden het erg leuk om samen muziek te maken. Een jaar
            later kwamen we elkaar weer tegen, dit keer in het Britten Jeugd
            Strijkorkest in Zwolle. We hebben met dit orkest ook in het ‘Concert
            voor 2 Violen en Orkest’ van de componist Malcolm Arnold mogen
            soleren. Na een tour door Italië met de meest prachtige concert
            locaties sprong de vonk echt tussen ons over. Al met al spelen we
            dus al lang samen. Het is iedere keer een feestje om samen muziek te
            maken. Onze karakters en ons spel zijn verschillend, maar dat zorgt
            er juist voor dat we elkaar perfect aanvullen. Als wij samen viool
            spelen hoeven we elkaar maar aan te kijken en we weten wat we
            bedoelen. Het voelt heel natuurlijk en vertrouwd. De laatste jaren
            hebben we meerdere keren samen concerten gegeven en besloten daarom
            een vast duo te vormen: Duo Juna.
          </p>
        </section>

        <div className="center-container">
          <LazyLoadImage className="text-img smile" src={smile} alt="" effect="blur" />
        </div>
        
        <section className="vivan-bio">
          <div className="vivian-intro">
            <div className="text">
              <h3>Vivian de Graaff</h3>
              <p className="long-text">
                Mijn naam is Vivian de Graaff en ik begon met viool
                spelen toen ik 6 was. Mijn opa Hans Wijnberg was mijn eerste
                docent. Vervolgens heb ik les mogen hebben van Petra Westra en
                Sylvia van der Grinten. Ik heb mijn bachelor viool gehaald aan
                het Koninklijk Conservatorium in Den Haag bij Ilona Sie Dhian Ho
                en op dit moment volg ik de master New Audiences and Innovative
                Practice (NAIP) met Janet Krause als mijn viool docente. In deze
                master hou ik me bezig met het ontdekken van nieuwe
                optreedmanieren voor onbekend publiek. Ik ben bezig met
                onderzoek in de Classic Express van het Prinses Christina
                Concours naar het effect van een verhaal vertellen met klassieke
                muziek ertussen op de emoties van het publiek (6-9 jaar), de
                concentratieboog en de interesse in klassieke muziek.
              </p>
            </div>
            <img className="doodles" src={vioolDoodle} alt="" />
          </div>
          <div className="vivian-second">
            <p className="paragraph long-text">
              <LazyLoadImage className="text-img" src={vivanSolo} alt=""  effect="blur" />
              <div>
                <img className="doodle doodles" src={vioolTurned} alt="" />
              </div>
              Ik hou van concerten geven, zowel solo als met ensembles en
              orkesten. In de ideale wereld zou ik mijn hele leven aan het
              conservatorium blijven studeren, om mijzelf continu optimaal te
              laten ontwikkelen met de beste docenten. Naast de ‘traditionele’
              manier van concerten geven hou ik mij ook veel bezig met manieren
              waarop optredens ook mogelijk zijn. Dit doe ik onder andere met
              Orkest Morgenstond, Duo Juna en het Ricciotti ensemble. Ik vind
              het belangrijk om echt contact te maken met mijn luisteraars en om
              klassieke muziek op een manier aan te bieden die bij het publiek
              aansluit. Ik werk liever vanuit de wens van het publiek naar het
              in elkaar zetten van een passend optreden, in plaats van een vaste
              concertopzet aanbieden aan elk verschillend publiek. Daarnaast
              geef ik ook vioolles, waarbij ik van dezelfde principes uit ga.
              Als je meer over mij wilt weten, kijk dan op mijn website{" "}
              <a
                className="link-to"
                href="https://www.viviool.nl"
                target="_blank"
              >
                viviool.nl!
              </a>
            </p>
          </div>
        </section>

        <section className="tina-bio">
          <h3>Tina Draadjer</h3>
          <p className="long-text">
            Hoi! Ik ben Tina Draadjer en ben op mijn 9de begonnen
            met viool spelen. Momenteel studeer ik klassiek viool aan het
            conservatorium van Utrecht bij Hebe Mensinga. Op jonge leeftijd ben
            ik terecht gekomen bij Coosje Wijzenbeek. Ik heb 5 jaar les van haar
            gehad en jaren lang concerten mogen geven met haar ensemble; de
            Fancy Fiddlers. Hier kreeg ik op jonge leeftijd lessen aan het
            conservatorium van Amsterdam. De jaren daarna heb ik les mogen
            krijgen van Arthur Ornée en Sylvia van der Grinten. Na mijn
            middelbare school ben ik aan mijn 1ste jaar aan het conservatorium
            van Amsterdam begonnen bij Kees Koelmans. Na 1 jaar daar veel
            geleerd te hebben, besloot ik toch om mijn studie te vervolgen aan
            het conservatorium in Utrecht, waar ik momenteel met veel plezier
            studeer en in 2022 mijn bachelor af ga ronden.
            <LazyLoadImage className="text-img" src={tinaSolo} alt="" effect="blur" />
            Van jongs af aan heb ik in veel orkesten/ensembles gespeeld. Het is
            ontzettend fijn als je je passie met anderen kan delen. Waar ik dan
            ook het liefst mee bezig ben is kamermuziek. Mijn droom is om later
            mijn eigen kamermuziekfestival te hebben. Daarnaast geef ik nu sinds
            een aantal jaar ook les, waar ik ook veel plezier aan beleef. Naast
            mijn klassieke viool studie vind ik het heel erg leuk om
            verschillende muziekstijlen (improviseren, pop, tango, wereldmuziek
            en jazz) te ontdekken en te spelen. Mijn doel is dan ook om muziek
            overal te brengen en van iedereen wat te leren. En bovenal
            natuurlijk te genieten van dat ik de mogelijkheid heb om iedere dag
            met muziek bezig te zijn! Muziek is een taal die iedereen verstaat,
            verbindt en voelt.
          </p>
        </section>
      </div>
    </Layout>
  );
}

export default overOns;
