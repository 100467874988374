import React from 'react'
import { Link } from 'gatsby'
import { menuItems } from "./links"
import NameLogo from "../../images/Name.png";
import Burger from "./Burger"

function NavbarMobile() {
  return (
    <div className="navbar-mobile-container">
      <Link to="/"><img className="logo-name" src={NameLogo} alt="" /></Link>
      <Burger menuItems={menuItems} />
    </div>
  )
}

export default NavbarMobile
