import React, { useState, useEffect, useLayoutEffect } from "react";

import Nav from "./NavBars/Nav";
import NavbarMobile from "./NavBars/NavbarMobile";
import Footer from "./Footer";

function Layout({ children }) {
  return (
    <div className="layout">
      <Nav />
      <NavbarMobile />
      <div className="layout-content">
        {children}
      </div>
      <Footer className="footer"/>
    </div>
  );
}

export default Layout;
