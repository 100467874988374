import React from 'react'
import { Link } from "gatsby";

import { menuItems } from "./links"

import NameLogoSmall from "../../images/name-logo-small.svg";

function Nav() {
  return (
    <div className="navbar-container">

      <div className="navbar-bg-container">

        <Link to="/"><img className="logo" src={NameLogoSmall} alt="alt" /></Link>

        <div className="navbar-items">
          {menuItems.map((item) => {
           return  <Link activeClassName="navbar-active" className="navbar-item" to={item.link}>{item.title}</Link>
          })}

        </div>
      </div>
    </div>
  )
}

export default Nav
